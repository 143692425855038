import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { RichText } from 'prismic-reactjs'
import React from 'react'
import { Basic } from '../components/Hero'
import PageFooter from '../components/PageFooter'
import PageHeader from '../components/PageHeader'
import { htmlSerializer } from '../utils/htmlSerializer'
import { linkResolver } from '../utils/linkResolver'
import { usePageContext } from '../utils/PageContext'
import { Head } from '../components/Head'

const PageTemplate = ({ data, pageContext }) => {
  const { lang } = usePageContext();

  if (!data) return null

  const document = data.prismicNews
  const navigation = data.prismicMultiLevelNavigation.data.body

  const category = document.data.category.document?.data.title || null
  return (
    <>
      <Head lang={lang} uid={document.uid} title={document.data.title}></Head>
      <PageHeader navigation={navigation} lang={pageContext.lang} altLangs={document.alternate_languages} />

      <Basic slice={{
        primary: {
          content: {
            raw:
              [{ spans: [], text: document.data.title, type: "heading1" }]
          }
        }
      }} />

      <div className={`o-container c-text`}>
        <RichText />
        {document.data.body.map((slice, i) => {
          const { slice_type } = slice
          switch (slice_type) {
            default: return <div key={`news-detail-${i}`} className={`m-auto max-w-3xl`}>
              <RichText render={slice.primary.content.raw} htmlSerializer={htmlSerializer} />
            </div>
          }
        })}
      </div>

      <PageFooter socials={data.prismicConfiguration} navigation={navigation} />
    </>
  )
}

export const query = graphql`
    query NewsDetailPageQuery($lang: String, $id: String) {
      ... MenuQuery
      prismicNews(lang: {eq: $lang}, id : {eq: $id}) {
        data {
          body {
            ... on PrismicNewsDataBodyTextblock {
              id
              slice_type
              primary {
                content {
                  raw
                }
              }
            }
          }
          short_description {
            raw
          }
          image {
            gatsbyImageData
          }
          title
          category {
            document {
              ... on PrismicNewscategory {
                id
                data {
                  title
                }
              }
            }
          }
        }
        alternate_languages {
            id
            type
            lang
            uid
            document {
              ... on PrismicNews {
                id
                url
              }
            }
          }
        uid
        url
        lang
      }

      prismicConfiguration(lang: { eq: "nl-be" }) {
        data {
          facebook {
            url
          }
          linkedin {
            url
          }
        }
      }

    }
  
`

export default withPrismicPreview(PageTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver
  },
])