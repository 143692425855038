import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Subhead } from './Text'

const Inline = ({slice}) => 
<section className={`container c-hero--${slice.primary.display_type || 'modern'}`}>
    <div className={`md:flex gap-10`}>
    <div className="md:max-w-[35%] md:min-w-[35%] p-8 c-text">
        <RichText render={slice.primary.title.raw}></RichText>
        <RichText render={slice.primary.content.raw}></RichText>
    </div>
    {slice.primary.image && (<GatsbyImage image={getImage(slice.primary.image.gatsbyImageData)} alt={slice.primary.image.alt || ""} />)}
    </div>
</section>

export const Basic = ({slice}) => 
<section className={`container c-hero--basic c-text`}>
    <Subhead content={slice.primary.content} title={slice.primary.title} />
</section>

const WithImage = ({slice}) => (<section className={`c-hero c-hero--${slice.primary.display_type || 'modern'}`} style={{backgroundColor: slice.primary.background_color || '#FFFFFF'}}>
{slice.primary.image && (<div className="c-hero__image">
    <GatsbyImage image={getImage(slice.primary.image.gatsbyImageData)} alt={slice.primary.image.alt || ""} />
</div>)}
<div className={`c-hero__wrapper`}>
    <div className="c-hero__inner">
        <div className="c-hero__main  c-text">
            <RichText render={slice.primary.title.raw}></RichText>
            <div className={`c-hero__content`}>
                <RichText render={slice.primary.content.raw}></RichText>
            </div>
        </div>
    </div>
</div>
</section>)

export const Hero = ({slice}) => {
    return (slice.primary.display_type === 'inline' ? 
        <Inline slice={slice} /> : 
        <>{slice.primary.image.gatsbyImageData  ? <WithImage slice={slice} /> : <Basic slice={slice} />}</>
    )
}

export default Hero

export const query = graphql`
  fragment HeroSlice on PrismicPageDataBodyHero {
      slice_type
      primary {
        background_color
        content {
            raw
        }
        image {
            alt
            gatsbyImageData
        }
        title {
            raw
        }
        display_type
    }
  }
`